<template>
  <div class="wrap">
    <p class="title">
        上传或拍摄
      <span>{{ userName }}</span>
         本人身份证
    </p>
    <p class="tx">请拍摄证件原照，并使照片中证件边缘</p>
    <p class="tx">完整、文字清晰不模糊。</p>
      <ul class="image-reader-list idCardDiv">
      <li
        class="image-reader-item"
        v-for="(img, index) in imageList['reader0']"
        :key="index"
        :style="{
          'backgroundImage': `url(${img})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
        }">
        <div class="wrongDiv" 
        v-if="ImgHeadStatus == 2 && imageList.reader0.length !==0" >
        <div class="wrongCont">
          <p class="wrongIcon"><img :src="wrongIcon" />上传失败</p>
          <p>{{ headErrorMsg }}</p>
        </div>
      </div>
      <!-- <div class="rightDiv" v-show="ImgHeadStatus == 1 && hasImgHead">
        <img class="rightIcon" :src="rightIcon" />
      </div> -->
      <!-- <img
        class="deleteIcon"
        :src="deleteIcon"
         @click="onDeleteImage('reader0', index)"
        v-show="hasImgHead"
      /> -->
       <md-tag
          class="image-reader-item-del"
          size="small"
          shape="quarter"
          fill-color="#111A34"
          type="fill"
          font-color="#fff"
          @click.native="onDeleteImage('reader0', index)"
        >
          <md-icon name="close" ></md-icon>
        </md-tag>

        <!-- <md-icon
          class="image-reader-item-del"
          name="circle-cross"
          color="#666"
          @click.native="onDeleteImage('reader0', index)">
        </md-icon> -->
      </li>
      <li class="image-reader-item addDiv" v-if="imageList.reader0.length==0" >
        <md-image-reader
          name="reader0"
          @select="onReaderSelect"
          @complete="onReaderComplete"
          @error="onReaderError"
          :is-multiple="false"
        ></md-image-reader>
        <img class="addIcon" :src="addIcon" /> 
        <p >上传身份证头像照</p>
      </li>
    </ul>

      <ul class="image-reader-list idCardDiv" style="margin-top: 16px">
      <li
        class="image-reader-item"
        v-for="(img, index) in imageList['reader1']"
        :key="index"
        :style="{
          'backgroundImage': `url(${img})`,
          'backgroundPosition': 'center center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
        }">
        
       <md-tag
          class="image-reader-item-del"
          size="small"
          shape="quarter"
          fill-color="#111A34"
          type="fill"
          font-color="#fff"
          @click.native="onDeleteImage('reader1', index)"
        >
          <md-icon name="close" ></md-icon>
        </md-tag>
       <div class="wrongDiv" v-show="ImgNationStatus == 2 && imageList.reader0.length !==0">
        <div class="wrongCont">
          <p class="wrongIcon"><img :src="wrongIcon" />上传失败</p>
          <p>{{ nationErrorMsg}}</p>
        </div>
      </div>
      <!-- <div class="rightDiv" v-show="ImgNationStatus == 1 && hasImgNation">
        <img class="rightIcon" :src="rightIcon" />
      </div>  -->
      <!-- <img
        class="deleteIcon"
        :src="deleteIcon"
         @click="onDeleteImage('reader1', index)"
        v-show="hasImgNation"
      /> -->
        <md-icon
          class="image-reader-item-del"
          name="circle-cross"
          color="#666"
          @click.native="onDeleteImage('reader1', index)">
        </md-icon>
      </li>
      <li class="image-reader-item addDiv" v-if="imageList.reader1.length==0" >
        <md-image-reader
          name="reader1"
          @select="onReaderSelect"
          @complete="onReaderComplete"
          @error="onReaderError"
          :is-multiple="false"
        ></md-image-reader>
        
        <img class="addIcon" :src="addIcon" /> 
        <p >上传身份证国徽照</p>
        <!-- <img class="addIcon" :src="addIcon"   v-show="!hasImgNation"/>  -->
        <!-- <p v-show="!hasImgNation">上传身份证国徽照</p> -->
      </li>
    </ul>


    <button  @click="submit" :disabled ="!isSubmitCanClick" :class="['submitBtn' ,isSubmitCanClick == false? 'disabledBtn' :'']">上传</button>
  
  </div>
</template>

<script>
import { Toast } from 'vant';
import addIcon from "@assets/add.png";
import deleteIcon from "@assets/delete.png";
import wrongIcon from "@assets/wrong.png";
import rightIcon from "@assets/right.png";
import {uploadIdCardImg ,gatherIdCard} from "@network/api";
import {Icon, ImageReader, Tag,Dialog ,Button} from 'mand-mobile'
import imageProcessor from 'mand-mobile/components/image-reader/image-processor'
import wx from "weixin-js-sdk";

export default {
  name: "",
  components:{
     [Button.name]: Button,
      [Icon.name]: Icon,
      [ImageReader.name]: ImageReader,
      [Tag.name]: Tag,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
     imageList: {
        reader0: [
        ],
        reader1: [],
      },
      userName: "",
      hasImgHead: false,
      hasImgNation: false,
      // 0 未查 1 成功 2错误
      ImgHeadStatus: 0,
      ImgNationStatus: 0,
      addIcon: addIcon,
      headErrorMsg: "",
      nationErrorMsg: "",
      deleteIcon: deleteIcon,
      wrongIcon: wrongIcon,
      rightIcon: rightIcon,
      ImgHeadWrong: false,
      token: "",
      fromPage:'',
      imageSizeLimit:null,
    isSubmitCanClick:false,
    frontImgUrl:'',
    backImgUrl:'',
       params: {
      frontImgUrl: '',
      backImgUrl: ''
    },
    };
  },
  created() {
    // 判断是否在小程序环境
    // wx.miniProgram.getEnv((res) => {
      console.log(window.location.href);
      this.imageSizeLimit = window.location.href.split("imageSizeLimit=")[1].split("&pageflag=")[0];
      console.log(this.imageSizeLimit);
      this.token =
        "Bearer " + window.location.href.split("token=Bearer%20")[1].split("&appid=")[0];
      window.localStorage.setItem("token", this.token);
      this.appid = window.location.href.split("appid=")[1];
      this.fromPage = window.location.href.split("pageflag=")[1].split("&token=")[0];
      console.log(this.fromPage);
      this.userName = decodeURI(window.location.href.split("userName=")[1]);
  },
  methods: {
    onReaderSelect(name, {files}) {
      Toast.loading({
        message: '图片读取中...',
        duration: 7000,
        forbidClick: true,
      });
    },
    onReaderComplete(name, {dataUrl, file}) {
      // 上传图片 一定回走到这里 不管有没有网
      // 此处获取base64的大小
      const tempFileSize = this.imageSize(dataUrl);
      // tempFileSize单位是 b 字节   1mb = 1024kb = 1024 *1024b  接口返回的是 kb
      if(this.imageSizeLimit){
          if (tempFileSize/1024> this.imageSizeLimit ) {
            Toast.fail('图片大小限制:' + (this.imageSizeLimit / 1024) + "MB"+'，请重新上传图片');
           return;
          }
      }else{
        if (tempFileSize/1024> 2048 ) {
            Toast.fail('图片大小限制:' + (this.imageSizeLimit / 1024) + "MB"+'，请重新上传图片');
            return;
          }
      }
      let imgname = name;
      let content = file;
      let data = new FormData();
      data.append("file", content);
      uploadIdCardImg(data)
      .then((res) => {
        try{
          console.log('res',res);
        if(res.code == '00000'){
       setTimeout(() => {
        const demoImageList = []
        demoImageList.push(dataUrl)
        this.$set(this.imageList, name, demoImageList)
      }, 100)
           Toast.clear()
        if (imgname =='reader0') {
          this.frontImgUrl = res.data;
          this.hasImgHead = true;
          this.isSubmitCanClick = true;
        } else if (imgname =='reader1') {
          this.hasImgNation = true;
          this.backImgUrl = res.data;
          this.isSubmitCanClick = true;
        } 
        }else{
         Toast.fail(res.message)
        }
        }catch(error){
          console.log('error',error);
           Toast.fail('上传照片失败，请重新上传')
        }
      })
    },
    onReaderError(name, {msg}) {
      Toast.fail(msg);
    },
    onDeleteImage(name, index) {
      const demoImageList = this.imageList[name] || []
      demoImageList.splice(index, 1)
      this.$set(this.imageList, name, demoImageList)
       this.isSubmitCanClick = true;
       if(name =='reader0'){
          (this.frontImgUrl = ""), (this.hasImgHead = false);
          this.ImgHeadStatus = 0;
       }else if(name =='reader1'){
         (this.backImgUrl = ""), (this.hasImgNation = false);
        this.ImgNationStatus = 0;
       }

    },
    // 获取base64 的大小  （jssdk 的 wx.chooseImage 不能拿到图片的size,通过base64拿到图片大小 ）
    imageSize(base64Str) {
      const indexBase64 = base64Str.indexOf('base64,');
      if (indexBase64 < 0) return -1;
      const str = base64Str.substr(indexBase64 + 6);
      return (str.length * 0.75).toFixed(2);
    },
    submit() {
    this.isSubmitCanClick = false;
    this.params.frontImgUrl = this.frontImgUrl;
    this.params.backImgUrl = this.backImgUrl;
    if (this.params.frontImgUrl == '' ) {
      Toast.fail('请先上传身份证头像照');
      return
    }
    if (this.params.backImgUrl == '') {
      Toast.fail('请先上传身份证国徽照')
      return
    }
    if (this.ImgHeadStatus == 2 || this.ImgNationStatus == 2) {
      Toast.fail('请上传正确的身份证照片')
      return
    }
     Toast.loading('OCR识别中...')
        setTimeout(() => {
            this.isSubmitCanClick = true;
        }, 8000);
     gatherIdCard(this.params).then((res) => {
       if(res.code == '00000'){
          Toast.clear()
          this.isSubmitCanClick = true;
          if(res.data.code == '00000'){
                 //；两面都正确 上传成功
                this.ImgHeadStatus = 1;
                this.ImgNationStatus = 1;
                if(this.fromPage ==1){
                  setTimeout(() => {
                    wx.miniProgram.navigateBack({
                    url: '/pages/cashout/cashout'
                      })
                    wx.miniProgram.postMessage({ data:{fromPage:1 }  });
                  }, 2000)
                }else if(this.fromPage ==2){
                  setTimeout(() => {
                    wx.miniProgram.navigateBack({
                    url: '/pages/redbag_cashout/redbag_cashout'
                      })
                    wx.miniProgram.postMessage({ data:{fromPage:2}  });
                  }, 2000)
                }else if(this.fromPage ==3){
                  setTimeout(() => {
                    wx.miniProgram.navigateBack({
                    url: '/pages/redbag_cashout/redbag_cashout'
                      })
                    wx.miniProgram.postMessage({ data:{fromPage:3}  });
                  }, 2000)
                }else if(this.fromPage ==4){
                  setTimeout(() => {
                    wx.miniProgram.navigateBack({
                    url: '/pages/mine/mine'
                      })
                    wx.miniProgram.postMessage({ data:{fromPage:4}  });
                  }, 2000)
                }else if(this.fromPage ==5){
                  setTimeout(() => {
                    wx.miniProgram.navigateBack({
                    url: '/pages/index/index'
                      })
                    wx.miniProgram.postMessage({ data:{fromPage:5}  });
                  }, 2000)
                }
          }else if(res.data.code == '30301' ||res.data.code == '30302'){
                 // 未检测到本人身份证照片 (正面)
                  // 未识别出有效身份证正面照
                  this.ImgHeadStatus = 2;
                  this.headErrorMsg = res.data.message;
          }else if(res.data.code == '30303' ){
                  // 未识别出有效身份证背面照
                  this.ImgNationStatus = 2;
                  this.nationErrorMsg = res.data.message;
          }else{ 
                 Toast.fail(res.data.message)
                 setTimeout(() => {
                      this.isSubmitCanClick = true;
                  }, 3000);
          }
       }else{
              Toast.fail(res.message)
              setTimeout(() => {
                      this.isSubmitCanClick = true;
                  }, 3000);
       }
    });
    },
  },
};
</script>

<style  lang="scss" scoped>
p {
  margin: 0;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.wrap {
  margin: 40px 44px;
  font-family: PingFang SC;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #333333;
  text-align: center;
  margin-bottom: 8px;
}
.title span {
  color: #1ac9c4;
}
.tx {
  font-size: 16px;
  line-height: 22px;
  color: #666;
  text-align: center;
}
.idCardDiv {
  position: relative;
  width: 240px;
  height: 150px;
  margin-top: 40px;
}

.addDiv p {
  font-size: 13px;
  line-height: 18px;
  color: #333;
  margin-top: 8px;
}
.bac {
  width: 100%;
  height: 150px;
  max-height: 150px;
}
li{
  margin: 0;
}

.addIcon {
  width: 48px;
  height: 48px;
}
.deleteIcon {
  position: absolute;
  width: 30px;
  right: -5px;
  top: -5px;
  height: 30px;
  z-index: 100;
}
.rightDiv {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}
.rightDiv .rightIcon {
  width: 75px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -36px;
}

.wrongDiv {
  border-radius: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
}
.wrongCont {
  position: absolute;
  top: 35px;
  left: 18px;
  width: 208px;
  color: #fff;
  flex-direction: column;
  align-items: center;
  display: flex;
}
.wrongDiv p {
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  margin-bottom: 8px;
}
.wrongDiv .wrongIcon {
  justify-content: center;
  display: flex;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  height: 18px;
  margin-bottom: 8px;
}
.wrongDiv .wrongIcon img {
  width: 16px;
  height: 16px;
}

.submitBtn {
  text-align: center;
  background: #1ac9c4;
  border-radius: 12px;
  width: 287px;
  height: 44px;
  line-height: 40px;
  margin-top: 40px;
  color: #fff;
      border: none;
}
.disabledBtn {
  text-align: center;
 background-color: #f8f8f8;
  border-radius: 12px;
  width: 287px;
  height: 44px;
  line-height: 40px;
  margin-top: 40px;
  color: #fff;
}
.image-reader-list{
     position: relative;
  width: 240px;
    height: 150px;
        border: 1px solid #f2f2f2;
    border-radius: 10px;
    background: #f7f7f7;
}
.image-reader-item{
    position: absolute;
    left: 0;
    width: 240px;
    height: 150px;
    top: 0;
}
.add{
position: absolute;
    left: 0;
    width: 240px;
    height: 150px;
    top: 0;
}

.addDiv{
      display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 240px;
    height: 150px;
    top: 0;
}
.md-image-reader{
      position: absolute;
    left: 0;
    width: 240px;
    height: 150px;
    top: 0;
}
 /deep/.md-image-reader-file{
       opacity: 0;
      position: absolute;
     width: 240px;
    height: 150px;
    left: 0;
    top: 0;
}


 .md-tag{
      position: absolute;
    right: 0;
    top: 0;
        z-index: 1000;
}

/deep/.md-toast{
      max-width: 90%;
      width: auto;
}
.md-toast-content{
    padding: 6px 10px;
    color: #fff;
        font-size: 14px;
    background-color: #333!important;
}
/deep/.md-toast-content{
    padding: 6px 10px;
    color: #fff;
        font-size: 14px;
    background-color: #333!important;
}
::v-deep .md-toast-content{
    padding: 6px 10px;
    color: #fff;
        font-size: 14px;
    background-color: #333!important;
}
/deep/ .md-icon {
    font-size: 25px;
}
/deep/ .md-toast-content .md-toast-text{
  background: none;
}

.md-toast .md-popup .md-popup-box{
      max-width: 90%;
      width: auto;
}
//  .md-toast-content{
//       font-size: 12px;
//     padding: 6px 10px;
//     color: #fff;
// }
.md-icon.icon-font.lg {
    font-size: 25px;
}
// .md-toast-content .md-toast-text{
//   background: none;
// }
.am-toast-text{
border:1px solid #FF0017;
background:rgba(255,255,255,0);
color: red;
}
</style>
