var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('p',{staticClass:"title"},[_vm._v(" 上传或拍摄 "),_c('span',[_vm._v(_vm._s(_vm.userName))]),_vm._v(" 本人身份证 ")]),_c('p',{staticClass:"tx"},[_vm._v("请拍摄证件原照，并使照片中证件边缘")]),_c('p',{staticClass:"tx"},[_vm._v("完整、文字清晰不模糊。")]),_c('ul',{staticClass:"image-reader-list idCardDiv"},[_vm._l((_vm.imageList['reader0']),function(img,index){return _c('li',{key:index,staticClass:"image-reader-item",style:({
        'backgroundImage': ("url(" + img + ")"),
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
      })},[(_vm.ImgHeadStatus == 2 && _vm.imageList.reader0.length !==0)?_c('div',{staticClass:"wrongDiv"},[_c('div',{staticClass:"wrongCont"},[_c('p',{staticClass:"wrongIcon"},[_c('img',{attrs:{"src":_vm.wrongIcon}}),_vm._v("上传失败")]),_c('p',[_vm._v(_vm._s(_vm.headErrorMsg))])])]):_vm._e(),_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('reader0', index)}}},[_c('md-icon',{attrs:{"name":"close"}})],1)],1)}),(_vm.imageList.reader0.length==0)?_c('li',{staticClass:"image-reader-item addDiv"},[_c('md-image-reader',{attrs:{"name":"reader0","is-multiple":false},on:{"select":_vm.onReaderSelect,"complete":_vm.onReaderComplete,"error":_vm.onReaderError}}),_c('img',{staticClass:"addIcon",attrs:{"src":_vm.addIcon}}),_c('p',[_vm._v("上传身份证头像照")])],1):_vm._e()],2),_c('ul',{staticClass:"image-reader-list idCardDiv",staticStyle:{"margin-top":"16px"}},[_vm._l((_vm.imageList['reader1']),function(img,index){return _c('li',{key:index,staticClass:"image-reader-item",style:({
        'backgroundImage': ("url(" + img + ")"),
        'backgroundPosition': 'center center',
        'backgroundRepeat': 'no-repeat',
        'backgroundSize': 'cover'
      })},[_c('md-tag',{staticClass:"image-reader-item-del",attrs:{"size":"small","shape":"quarter","fill-color":"#111A34","type":"fill","font-color":"#fff"},nativeOn:{"click":function($event){return _vm.onDeleteImage('reader1', index)}}},[_c('md-icon',{attrs:{"name":"close"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.ImgNationStatus == 2 && _vm.imageList.reader0.length !==0),expression:"ImgNationStatus == 2 && imageList.reader0.length !==0"}],staticClass:"wrongDiv"},[_c('div',{staticClass:"wrongCont"},[_c('p',{staticClass:"wrongIcon"},[_c('img',{attrs:{"src":_vm.wrongIcon}}),_vm._v("上传失败")]),_c('p',[_vm._v(_vm._s(_vm.nationErrorMsg))])])]),_c('md-icon',{staticClass:"image-reader-item-del",attrs:{"name":"circle-cross","color":"#666"},nativeOn:{"click":function($event){return _vm.onDeleteImage('reader1', index)}}})],1)}),(_vm.imageList.reader1.length==0)?_c('li',{staticClass:"image-reader-item addDiv"},[_c('md-image-reader',{attrs:{"name":"reader1","is-multiple":false},on:{"select":_vm.onReaderSelect,"complete":_vm.onReaderComplete,"error":_vm.onReaderError}}),_c('img',{staticClass:"addIcon",attrs:{"src":_vm.addIcon}}),_c('p',[_vm._v("上传身份证国徽照")])],1):_vm._e()],2),_c('button',{class:['submitBtn' ,_vm.isSubmitCanClick == false? 'disabledBtn' :''],attrs:{"disabled":!_vm.isSubmitCanClick},on:{"click":_vm.submit}},[_vm._v("上传")])])}
var staticRenderFns = []

export { render, staticRenderFns }